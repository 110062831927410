// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "chartkick/chart.js"

Rails.start()
ActiveStorage.start()

import 'bootstrap';
require('jquery')

// SCSS
import '../../src/stylesheet/customer/application';

// JavaScript
import '../../src/javascript/customer/stores/index';

const images = require.context('../../images/', true)
